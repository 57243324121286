<template>
  <!-- <a class="card h-100" :href="'/' + item.url"> -->
  <router-link class="card h-100" :to="{ path: `/${item.url}` }">
    <div class="card-img-top position-relative">
      <img class="card-img-top" :src="require('@/assets/img/' + item.image_url)">
      <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="d-block small font-weight-bold text-cap">{{ item.category_title }}</span>
        <span v-if="item.status" class="badge badge-soft-secondary">{{ item.status }}</span>
      </div>
      <div class="mb-5">
        <h4>{{ item.title }}</h4>
        <p v-if="item.description" class="font-size-1">{{ item.description }}</p>
      </div>
      <div v-if="item.date" class="media text-body font-size-1 mb-2">
        <div class="min-w-3rem text-center mr-3">
          <i class="far fa-calendar-alt" />
        </div>
        <div class="media-body">
          {{ item.date }}
        </div>
      </div>
      <div v-if="item.time" class="media text-body font-size-1 mb-2">
        <div class="min-w-3rem text-center mr-3">
          <i class="far fa-clock" />
        </div>
        <div class="media-body">
          {{ item.time }}
        </div>
      </div>
    </div>
    <div class="card-footer border-0 pt-0">
      <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
    </div>
  </router-link>
  <!-- </a> -->
</template>

<script>
import 'bootstrap'

export default {
  name: 'ResourcesCard',
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          url: '',
          image_url: '',
          category: '',
          title: '',
          description: '',
          date: '',
          time: ''
        }
      }
    }
  }
}
</script>
