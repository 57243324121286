<template>
  <main id="content" role="main">

    <!-- ========== Hero Section ========== -->
    <div class="gradient-y-sm-primary position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-3.svg') + ')', 'background-color': '#F0F8FD;'}">
      <div class="container container space-top-3 space-bottom-2 mt-lg-5">
        <!-- Title -->
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <h1 class="mb-3">Resource Library</h1>
            <p class="lead">Connect with the beNovelty community to learn the advice, tips, and tricks to power data driven decisions.</p></div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Event listing Section ========== -->
    <div class="container space-2">
      <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selected_category" type="radio" name="categories" :value="item.category">
      <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
        <li v-for="item in category_list" :key="item.category">
          <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1" :class="{'selectedCategory': selected_category === item.category}" :for="item.category">{{ item.category_title }}</label>
        </li>
      </ol>
      <ol class="filters-container" style="padding: 0 0.5rem 0.5rem 0.5rem">
        <div class="row">
          <li v-for="item in filtered_category_list" :key="item.url" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
            <ResourcesCard :item="item" />
          </li>
        </div>
      </ol>

    </div>
    <!-- ========== End Event Listing Section ========== -->

  </main>
</template>

<script>
import ResourcesCard from '@/components/resources-card'
import { mapGetters } from 'vuex'
export default {
  name: 'ResourceLibrary',
  components: {
    ResourcesCard
  },
  data() {
    return {
      category_list: [],
      filtered_category_list: [],
      resource_list: [],
      selected_category: 'All'
    }
  },
  computed: {
    ...mapGetters([
      'resources'
    ])
  },
  created() {
    this.resource_list = this.$store.getters.resources.resource_list
    this.filtered_category_list = this.resource_list.filter(item => {
      return item.hidden !== true
    })
    this.getCategoryList(this.filtered_category_list)
  },
  methods: {
    getCategoryList(list) {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = list[i].category_title
        object.category = list[i].category
        array = array.concat(object)
      }
      const map = new Map()
      for (const item of array) {
        if (!map.has(item.category_title)) {
          map.set(item.category_title, true)
          temp.push({
            category_title: item.category_title,
            category: item.category
          })
        }
      }
      temp.sort(compare)
      this.category_list = [{ category_title: 'All', category: 'All' }]
      this.category_list = this.category_list.concat(temp)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Resource Library | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/resource-library' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Resource Library | beNovelty' },
        { property: 'og:description', content: 'Connect with the beNovelty community to learn the advice, tips, and tricks to power data driven decisions.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/resource-library' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
